/**
 * This template is used to render author index page
 *
 * @flow
 */
import React from 'react';
import { graphql } from 'gatsby';

import type { Props } from '../components/PostArchive';
import PostArchive from '../components/PostArchive';

const AuthorTemplate = ({ pageContext, data }: Props) => (
  <PostArchive
    pageContext={pageContext}
    data={data}
  />
);

export default AuthorTemplate;

export const pageQuery = graphql`
  query AuthorTemplateQuery($skip: Int!, $limit: Int!, $author: String!) {
    posts: allWordpressWpPostsAndGuides(
      sort: {fields: date, order: DESC}
      skip: $skip
      limit: $limit
      filter: {author: {id: {eq: $author}}}
    ) {
      edges {
        node {
          id
          title
          path
          excerpt
          type
          featuredImage: featured_media {
            ...FeaturedMedia
          }
          tags {
            path
            name
          }
          categories {
            path
            name
          }
          customMeta: et_custom_meta {
            commentCount: comment_count
          }
        }
      }
    }
    allTags: allWordpressTag(sort: {fields: count, order: DESC}, filter: {count: {gt: 0}}) {
      edges {
        node {
          id
          path
          name
        }
      }
    }
    allCategories: allWordpressCategory(sort: {fields: count, order: DESC}, filter: {count: {gt: 0}}) {
      edges {
        node {
          id
          path
          name
        }
      }
    }
  }
`;
